import { v4 as uuidv4 } from 'uuid';

const _JOURNAL_ISSUES = [
  {
    id: uuidv4(),
    type: "issue",
    title:
      "International Journal of Research , Vol. 13 Issue 2, Jan-June 2024, ISSN 2231-6124",
    link: "assets/docs/journal-issues/Rizvi-IJR-Jan-Jun-2024.pdf",
  },
  {
    id: uuidv4(),
    type: "issue",
    title:
      "International Journal of Research, Vol. 13 Issue 1, July-Dec 2023, ISSN 2231-6124",
    link: "assets/docs/journal-issues/Rizvi-IJR-Jul-Dec-2023.pdf",
  },
  {
    id: uuidv4(),
    type: "issue",
    title:
      "International Journal of Research, Vol. 12 Issue 2, Jan-June 2023, ISSN 2231-6124",
    link: "assets/docs/journal-issues/Rizvi-IJR-Jan-June-2023.pdf",
  },
  {
    id: uuidv4(),
    type: "issue",
    title:
      "International Journal of Research, Vol. 12 Issue 1, July-Dec 2022, ISSN 2231-6124",
    link: "assets/docs/journal-issues/Rizvi-IJR-July-Dec-2022.pdf",
  },
  {
    id: uuidv4(),
    type: "issue",
    title:
      "International Journal of Research, Vol. 11 Special Issue March 2022, ISSN 2231-6124",
    link: "assets/docs/journal-issues/Rizvi-International-Journal-Special-Issue-2022.pdf",
  },
  {
    id: uuidv4(),
    type: "issue",
    title:
      "International Journal of Research, Vol. 11 Issue 2, Jan-June 2022, ISSN 2231-6124",
    link: "assets/docs/journal-issues/Rizvi-IJR-Jan-June-2022.pdf",
  },
  {
    id: uuidv4(),
    type: "issue",
    title:
      "International Journal of Research, Vol. 11 Issue 1, July-Dec 2021, ISSN 2231-6124",
    link: "assets/docs/journal-issues/Rizvi-IJR-July-Dec-2021.pdf",
  },
  {
    id: uuidv4(),
    type: "issue",
    title:
      "International Journal of Research, Vol. 10 Issue 2, Jan-June 2021, ISSN 2231-6124",
    link: "assets/docs/journal-issues/Rizvi-IJR-Jan-June-2021.pdf",
  },
  {
    id: uuidv4(),
    type: "issue",
    title:
      "International Journal of Research, Vol. 10 Issue 1, July-Dec 2020, ISSN 2231-6124",
    link: "assets/docs/journal-issues/Rizvi-IJR-July-Dec-2020.pdf",
  },
  {
    id: uuidv4(),
    type: "issue",
    title:
      "International Journal of Research, Vol. 9 Issue 2, Jan-Jun 2020, ISSN 2231-6124",
    link: "assets/docs/journal-issues/Rizvi-IJR-Jan-June-2020.pdf",
  },
  {
    id: uuidv4(),
    type: "issue",
    title:
      "International Journal of Research, Vol. 9 Issue 1, July-Dec 2019, ISSN 2231-6124",
    link: "assets/docs/journal-issues/Rizvi-IJR-July-Dec-2019.pdf",
  },
  {
    id: uuidv4(),
    type: "issue",
    title:
      "International Journal of Research, Vol. 8 Issue 2, Jan-Jun 2019, ISSN 2231-6124",
    link: "assets/docs/journal-issues/Rizvi-IJR-Jan-June-2019.pdf",
  },
  {
    id: uuidv4(),
    type: "issue",
    title:
      "International Journal of Research, Vol. 8 Issue 1, Jul-Dec 2018, ISSN 2231-6124",
    link: "assets/docs/journal-issues/Rizvi-IJR-July-Dec-2018.pdf",
  },
  {
    id: uuidv4(),
    type: "issue",
    title:
      "International Journal of Research, Vol. 7 Issue 2, Jan-Jun 2018, ISSN 2231-6124",
    link: "assets/docs/journal-issues/Rizvi-IJR-Jan-June-2018.pdf",
  },
  {
    id: uuidv4(),
    type: "issue",
    title:
      "International Journal of Research, Vol. 7 Issue 1, Jul-Dec 2017, ISSN 2231-6124",
    link: "assets/docs/journal-issues/Rizvi-IJR-July-Dec-2017.pdf",
  },
];




export default _JOURNAL_ISSUES