import { Box, Typography } from "@mui/material";
import React from "react";

const CallForPapers = () => {
  return (
    <Box mx={2} my={2}>
      <Typography variant="h6" mb={2} align="center">
        Call For Papers
      </Typography>

      <Typography mb={4} fontWeight={600}>
        Call for papers for International Journal of Research, Volume 14 Issue 1
        (July - December 2024), ISSN 2231-6124, Peer Reviewed Biannual Indexed
        Journal with Impact Factor 8.852 (SJIF 2024).
        <br />
        The last date for sending the paper is till 15th November 2024
        <br />
        Email id:{" "}
        <a href="mailto:executiveeditor.ijr@rizvicollege.edu.in">
          executiveeditor.ijr@rizvicollege.edu.in
        </a>
        .<br />
        For more details, one can check the journal website{" "}
        <a href="www.internationaljournalofresearch.co.in">
          www.internationaljournalofresearch.co.in
        </a>
      </Typography>

      <Typography mb={4} align="justify">
        The Rizvi Education Society was founded by Dr. Akhtar Hasan Rizvi in the
        year 1982, who set his heart on the realization of a dream “The
        Establishment of a Mini University”, catering to diverse educational
        interests and aspirations. Rizvi College of Arts, Science &amp; Commerce
        was established in the year 1985- 86. Today Rizvi Education Society’s
        Empire has expanded to unimaginable dimensions. There are many Rizvi
        institutes, not only in Mumbai but also in Jaunpur and Allahabad.
      </Typography>

      <Typography mb={4} align="justify">
        Rizvi College of Arts, Science &amp; Commerce has come out with this
        print blind peer reviewed indexed research journal titled “International
        Journal of Research” with ISSN 2231-6124. The journal is bi-annual in
        nature. The aim of the journal is to provide a platform for researchers,
        practitioners, academicians, and professionals from diverse domains to
        share innovative research achievements. The journal is dedicated to
        publishing high-quality research papers providing meaningful insights
        into any subject area of current interest.
      </Typography>

      <Typography mb={4} align="justify">
        We have on board, renowned academicians and professionals of national
        and international acclaim. Most of our editorial board members are
        heading or are part of institutions of high repute. Publishing papers in
        reputed journals is now the requirement of today. We have got
        overwhelming response from authors from India and abroad for all our
        earlier issues.
      </Typography>

      <Typography mb={4} align="justify">
        Rizvi journal was listed in UGC (Journal No. 63072, Serial No 106) in
        the multidisciplinary section. The journal is also listed in the
        international database of the Scientific Journal of Research (SJIF)
        Journals Master List database with impact factors 3.508 (2015), 4.011
        (2016), 5.002 (2017), 6.67(2018), 7.006(2019), 7.551(2020), 7.662
        (2021), 8.694 (2022) and 8.734 (2023)
      </Typography>

      <Typography mb={4} align="justify">
        Please see details about the editorial board, Author’s guidelines,
        <a href=" http://internationaljournalofresearch.co.in/guidelines/">
           http://internationaljournalofresearch.co.in/guidelines/
        </a>{" "}
        subscription details, order form, contents of the earlier issues, etc.
        of our journal are available on the journal website{" "}
        <a href="http://internationaljournalofresearch.co.in">
          http://internationaljournalofresearch.co.in
        </a>{" "}
        or college website{" "}
        <a href="www.rizvicollege.edu.in">www.rizvicollege.edu.in</a>.
      </Typography>

      <Typography mb={4} align="justify">
        Kindly spread this information. You can also request your college
        library to subscribe to the journal.
      </Typography>

      <Typography mb={4} align="justify">
        Thank you with warm regards,
      </Typography>

      <Typography mb={4} align="justify">
        Yours sincerely,
      </Typography>

      <Typography mb={4} align="justify">
        <strong>Dr. Ashfaq Ahmad Khan</strong>
        <br />
        Editor-in -Chief
      </Typography>

      <Typography mb={4} align="justify">
        <strong>Dr. (Mrs.) Anjum Ara M. K. Ahmad</strong>
        <br />
        Executive Editor
      </Typography>
    </Box>
  );
};

export default CallForPapers;
